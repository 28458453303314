
// configuration task routes
const task = '/garden/configuration/task/'
export const taskList = task + 'list'
export const taskStore = task + 'store'
export const taskUpdate = task + 'update'
export const taskToggle = task + 'toggle-status'

// configuration wages_head routes
const wagesHead = '/garden/configuration/wages-head/'
export const wagesHeadList = wagesHead + 'list'
export const wagesHeadStore = wagesHead + 'store'
export const wagesHeadUpdate = wagesHead + 'update'
export const wagesHeadToggle = wagesHead + 'toggle-status'

const pluckingOtAmount = '/garden/configuration/plucking-ot-amount/'
export const pluckingOtAmountList = pluckingOtAmount + 'list'
export const pluckingOtAmountStore = pluckingOtAmount + 'store'
export const pluckingOtAmountUpdate = pluckingOtAmount + 'update'

// configuration management salary route
const managementSalary = '/garden/configuration/create-salary/management/'
export const managementSalaryList = managementSalary + 'list'
export const managementSalaryStore = managementSalary + 'store'
export const managementSalaryDetailsApi = managementSalary + 'details'

// configuration management salary route
const staffSalary = '/garden/configuration/create-salary/staff/'
export const staffSalaryList = staffSalary + 'list'
export const staffSalaryStore = staffSalary + 'store'
export const staffSalaryView = staffSalary + 'view'

// configuration satff monthly salary route
const staffSalaryMonthly = '/garden/configuration/create-salary/staff-monthly/'
export const staffSalaryMonthlyList = staffSalaryMonthly + 'list'
export const staffSalaryMonthlyStore = staffSalaryMonthly + 'store'
export const staffSalaryMonthlyView = staffSalaryMonthly + 'view'

// configuration satff monthly salary route
const warkerSalaryMonthly = '/garden/configuration/create-salary/warker-monthly/'
export const warkerSalaryMonthlyList = warkerSalaryMonthly + 'list'
export const warkerSalaryMonthlyStore = warkerSalaryMonthly + 'store'
export const warkerSalaryMonthlyView = warkerSalaryMonthly + 'view'

// task assign routes
const taskAssignBaseUrl = '/garden/task-assign/'
export const taskAssignList = taskAssignBaseUrl + 'list'
export const taskAssignStore = taskAssignBaseUrl + 'store'
export const taskAssignUpdate = taskAssignBaseUrl + 'update'
export const taskAssignShow = taskAssignBaseUrl + 'show'
export const taskAssignWorkerList = taskAssignBaseUrl + 'worker-list'
export const taskAssignSaveAchievement = taskAssignBaseUrl + 'save-achievement'
export const taskAssignApprove = taskAssignBaseUrl + 'approve'
export const taskAssignIndividualAchievement = taskAssignBaseUrl + 'individual-achievement'
export const taskAssignSaveIndividualAchievement = taskAssignBaseUrl + 'save-individual-achievement'
export const taskAssignGiveSlip = taskAssignBaseUrl + 'give-slip'
export const taskAssignAchievementList = taskAssignBaseUrl + 'achievement-list'
export const taskAssignAchievementSlip = taskAssignBaseUrl + 'achievement-slip'
export const taskAssignGiveSlipMultiple = taskAssignBaseUrl + 'give-slip-multiple'

// salary management advance routes
const advanceSalary = '/garden/salary-management/advance-salary/'
export const advanceSalaryList = advanceSalary + 'list'
export const advanceSalaryStore = advanceSalary + 'store'
export const advanceSalaryUpdate = advanceSalary + 'update'
export const advanceSalaryToggle = advanceSalary + 'toggle-status'
export const searchWorkerInfoApi = advanceSalary + 'search-worker-info'

const masterProvidentFund = '/garden/configuration/provident-fund/'
export const providentFundList = masterProvidentFund + 'list'
export const providentFundStore = masterProvidentFund + 'store'
export const providentFundUpdate = masterProvidentFund + 'update'
export const providentFundToggle = masterProvidentFund + 'toggle-status'

// btb fertilizer circular route
const circular = '/btb/circular/'
export const circularList = circular + 'get-circular'
// Ex-garden application routes
const exGardenApp = '/btb/ex-garden-app/'
export const exGardenAppList = exGardenApp + 'list'
export const exGardenAppStore = exGardenApp + 'store'
export const exGardenAppUpdate = exGardenApp + 'update'
export const exGardenAppStatus = exGardenApp + 'toggle-status'
export const exGardenData = exGardenApp + 'ex-garden-data'

// Fertilizer request application routes
const fertilizerRequest = '/btb/fertilizer-request/'
export const fertilizerRequestList = fertilizerRequest + 'list'
export const fertilizerFinalAllocationList = fertilizerRequest + 'final-allocation-list'
export const fertilizerRequestStore = fertilizerRequest + 'store'
export const fertilizerRequestUpdate = fertilizerRequest + 'update'
export const lastYearFertilizerReqesHistory = fertilizerRequest + 'get-last-year-firtilizer-history'
export const fertilizerRequestView = fertilizerRequest + 'show'
export const fertilizerRequestNomineeStore = fertilizerRequest + 'nominee-store'
export const fertilizerRequestViewPdf = fertilizerRequest + 'show-pdf'

// Return Management
const returnGarden = '/garden/return-management/'
// return - one apis
export const returnOneListApi = returnGarden + 'return-one/list'
export const returnOneDetailsApi = returnGarden + 'return-one/details'
export const returnOneDetailsPdf = returnGarden + 'return-one/details-pdf'
export const returnOneTabOneStoreApi = returnGarden + 'return-one/store-tab-one'
export const returnOneTabTwoStoreApi = returnGarden + 'return-one/store-tab-two'
export const returnOneTabThreeStoreApi = returnGarden + 'return-one/store-tab-three'
export const returnOneTabFourStoreApi = returnGarden + 'return-one/store-tab-four'
export const returnOneTabLastStoreApi = returnGarden + 'return-one/store-tab-last'
export const ApprovalSaveApi = returnGarden + 'return-one/approve'
export const ReviewSaveApi = returnGarden + 'return-one/review'

// return - two apis
export const returnTwoListApi = returnGarden + 'return-two/list'
export const returnTwoDetailsApi = returnGarden + 'return-two/details'
export const returnTwoReportApi = returnGarden + 'return-two/report'
export const returnTwoDetailsPdf = returnGarden + 'return-two/details-pdf'
export const returnTwoTabOneStoreApi = returnGarden + 'return-two/store-tab-main'
export const returnTwoTabLastStoreApi = returnGarden + 'return-two/store-tab-last'
export const returnTwoOpeningStockApi = returnGarden + 'return-two/opening-stock-prev'
export const returnTwoApproveApi = returnGarden + 'return-two/approve'
export const returnTwoReviewSaveApi = returnGarden + 'return-two/review'

// return - three apis
export const returnThreeListApi = returnGarden + 'return-three/list'
export const returnThreeStoreApi = returnGarden + 'return-three/store'
export const returnThreeUpdateApi = returnGarden + 'return-three/update'
export const returnThreeShowApi = returnGarden + 'return-three/show'
export const preYearData = returnGarden + 'return-three/preYearData'
export const returnThreeApproveApi = returnGarden + 'return-three/approve'
export const returnThreeReviewSaveApi = returnGarden + 'return-three/review'

// Tea Production Stock in api..
const stockInBaseUrl = '/garden/tea-production-stock-in/'
export const stockInList = stockInBaseUrl + 'list'
export const stockInStore = stockInBaseUrl + 'store'
export const getFactoryGreenLeafCurrentStockApi = stockInBaseUrl + 'factory-green-leaf-current-stock'

// Tea Sell in api..
const teaSell = '/garden/tea-sale/'
export const teaSellList = teaSell + 'list'
export const teaSellStore = teaSell + 'store'
export const teaSaleDetailsApi = teaSell + 'show'
export const teaProBrokerCheckStock = teaSell + 'broker-tea-current-stock'

// Tea transfer to broker api..
const teaTransferBrokerBaseUrl = '/garden/tea-transfer-broker/'
export const teaTransferBrokerList = teaTransferBrokerBaseUrl + 'list'
export const teaTransferBrokerStore = teaTransferBrokerBaseUrl + 'store'
export const teaTransferBrokerCheckStock = teaTransferBrokerBaseUrl + 'check-tea-type-stock'
// online advisory visit application api
const onlineAdvVisit = '/garden/online-adv-visit/'
export const onlineAdvVisitList = onlineAdvVisit + 'list'
export const onlineAdvVisitStore = onlineAdvVisit + 'store'
export const onlineAdvVisitUpdate = onlineAdvVisit + 'update'
export const onlineAdvVisitShow = onlineAdvVisit + 'show'

// Green Leaf Transfer Factory
const greenLeafTransferFactoryUrl = '/garden/green-leaf-stock/'
export const greenLeafTransferFactoryListApi = greenLeafTransferFactoryUrl + 'green-leaf-transfer-factory/list'
export const greenLeafTransferFactoryStoreApi = greenLeafTransferFactoryUrl + 'green-leaf-transfer-factory/store'
export const greenLeafTransferFactoryUpdateApi = greenLeafTransferFactoryUrl + 'green-leaf-transfer-factory/update'
export const getGardenCurrentStock = greenLeafTransferFactoryUrl + 'green-leaf-transfer-factory/garden-current-stock'
export const greenLeadReceiveFromGardenSubmitApi = greenLeafTransferFactoryUrl + 'green-leaf-transfer-factory/green-leaf-receive-from-garden'
export const greenLeafReceiveFromGardenListApi = greenLeafTransferFactoryUrl + 'green-leaf-transfer-factory/green-leaf-receive-from-garden-list'

const gardenStockInOut = '/garden/green-leaf-stock-management/garden-stock-in-out/'
export const gardenStockInOutReportList = gardenStockInOut + 'reportlist'
export const gardenStockInOutList = gardenStockInOut + 'list'
export const gardenStockInOutStore = gardenStockInOut + 'store'
export const gardenStockInOutUpdate = gardenStockInOut + 'update'
export const gardenCurrentStockList = gardenStockInOut + 'current'

const gardenStockIn = '/garden/green-leaf-stock-management/garden-stock-in/'
export const gardenStockInList = gardenStockIn + 'list'
export const gardenStockInStore = gardenStockIn + 'store'
export const gardenStockInUpdate = gardenStockIn + 'update'

const gardenStockOut = '/garden/green-leaf-stock-management/garden-stock-out/'
export const gardenStockOutList = gardenStockOut + 'list'
export const gardenStockOutStore = gardenStockOut + 'store'
export const gardenStockOutUpdate = gardenStockOut + 'update'
