<template>
    <div class="inner-section">
        <card>
            <!-- search section start -->
            <template v-slot:searchHeaderTitle>
            </template>
            <template v-slot:searchBody>
            </template>
            <!-- search section end -->
        </card>

        <body-card>
            <!-- table section start -->
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('teaGardenConfig.plucking_ot_amount') }}</h4>
            </template>
            <template v-slot:body>
                <template>
                    <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                        <b-overlay :show="loading">
                            <b-row v-show="editStatus">
                                <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off"
                                    enctype="multipart/form-data">
                                    <b-row>
                                        <b-col lg="8" md="8" sm="12">
                                            <ValidationProvider name="Add Amount" vid="add_amount" rules=""
                                                v-slot="{ errors }">
                                                <b-form-group label-cols-sm="4" label-for="add_amount">
                                                    <template v-slot:label>
                                                        {{ $t('teaGarden.add_amount') }}
                                                    </template>
                                                    <b-form-input
                                                    id="add_amount"
                                                    v-model="form.add_amount"
                                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                    :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col lg="8" md="8" sm="12">
                                            <ValidationProvider name="Minus Amount" vid="minus_amount" rules=""
                                                v-slot="{ errors }">
                                                <b-form-group label-cols-sm="4" label-for="minus_amount">
                                                    <template v-slot:label>
                                                        {{ $t('teaGarden.minus_amount') }}
                                                    </template>
                                                    <b-form-input
                                                    id="minus_amount"
                                                    v-model="form.minus_amount"
                                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                    :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-row>
                                    <b-row class="text-right">
                                        <b-col>
                                            <b-button type="submit" variant="success" class="mr-2 btn-sm">{{
                                                $t('globalTrans.update') }}</b-button>
                                        </b-col>
                                    </b-row>
                                </b-form>
                            </b-row>
                            <b-row v-show="hideView">
                                <b-row>
                                    <b-col md="12">
                                        <table style="line-height: 40px" class="table table-sm table-borderless">
                                            <tr>
                                                <th style="width: 10%">{{ $t('teaGarden.add_amount') }}</th>
                                                <td style="width: 50%"> : {{ $n(form.add_amount) }}</td>
                                            </tr>
                                            <tr>
                                                <th style="width: 10%">{{ $t('teaGarden.minus_amount') }}</th>
                                                <td style="width: 50%"> : {{ $n(form.minus_amount) }}</td>
                                            </tr>
                                        </table>
                                    </b-col>
                                </b-row>
                                <b-row class="text-right">
                                    <b-col>
                                        <b-button type="submit" variant="success" @click="upStatus()" class="mr-2 btn-sm"><i
                                                class="ri-pencil-fill"></i> {{ $t('globalTrans.edit') }}</b-button>
                                    </b-col>
                                </b-row>
                            </b-row>
                        </b-overlay>
                    </ValidationObserver>
                </template>
            </template>
        </body-card>
    </div>
</template>

<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { pluckingOtAmountList, pluckingOtAmountStore, pluckingOtAmountUpdate } from '../../../api/routes'
export default {
    name: 'Form',
    components: {
    },
    data () {
        return {
            valid: null,
            Update: '',
            errors: [],
            editStatus: 0,
            hideView: 1,
            form: {
                id: 0,
                add_amount: '',
                minus_amount: ''
            }
        }
    },
    created () {
        this.loadData()
    },
    computed: {
        loading: function () {
            return this.$store.state.commonObj.loading
        }
    },
    methods: {
        upStatus () {
            this.editStatus = !this.editStatus
            this.hideView = !this.hideView
        },
        async saveUpdate () {
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadingState = { loading: false, listReload: false }
            if (this.form.id) {
                result = await RestApi.putData(teaGardenServiceBaseUrl, `${pluckingOtAmountUpdate}/${this.form.id}`, this.form)
                this.form = result.data
            } else {
                result = await RestApi.postData(teaGardenServiceBaseUrl, pluckingOtAmountStore, this.form)
                this.form = result.data
            }

            loadingState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadingState)

            if (result.success) {
                this.$store.dispatch('TeaGardenService/mutateCommonObj', { hasDropdownLoaded: false })
                this.$toast.success({
                    title: 'Success',
                    message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                    color: '#D6E09B'
                })
            } else {
                this.$refs.form.setErrors(result.errors)
            }
            this.upStatus()
        },
        async loadData () {
            const params = Object.assign({}, this.search)
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            const result = await RestApi.getData(teaGardenServiceBaseUrl, pluckingOtAmountList, params)
            if (result.success) {
                const listData = result.data
                if (listData !== null) {
                    this.form = listData
                }
            }
            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        }
    }
}
</script>
